@import "../../Theme/colors";

.react-joyride__beacon {
  span:first-child {
    background-color: rgb(255, 255, 68) !important;
  }

  span:last-child {
    background-color: rgba(255, 255, 68, 0.2) !important;
    border: 2px solid rgb(255, 255, 68) !important;
  }
}

.home-screen {
  .page__background {
    background-image: url(../../Assets/images/gradient-green-green.png);
  }

  .header-container {
    // padding-top: 10px;
    height: 10%;
    align-items: center;
    background-color: #ffffff;
    filter: drop-shadow(0 1mm 1mm rgb(230, 230, 230));
  }

  .account-icon {
    //width: 20%;
    padding: 20px;
  }

  .todays-heading {
    white-space: nowrap;
    div {
      padding-right: 10px;
      border-right: 1px solid $ihealWhite;
    }
  }
  .the-quote {
    padding-left: 10px;
    padding-right: 10px;
  }

  .home-explore {
    text-align: center;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .list-container {
    margin-top: 20%;
  }

  .pillar-card {
    width: 48%;
    margin: 0 auto 16px;
    cursor: pointer;
    border: 1px solid #f3f3f4;
    border-radius: 20px;

    .svg-inline--fa {
      margin-right: 5px;
    }

    .card-title {
      text-align: center;
      font-weight: 600;
      font-size: 1.1em;
    }
  }

  .saved-icon {
    // position: absolute;
    // left: 84%;
  }

  .blue {
    background-color: #a9d5ff;
  }

  .yellow {
    background-color: #ffc86c;
  }

  .green {
    background-color: #b6da83;
  }

  .purple {
    background-color: #d3bee3;
  }

  .red {
    background-color: #ffc4bc;
  }

  .brown {
    background-color: #c2b49b;
  }
}

.sidebar {
  .sidebar-item {
    font-size: 0.6em;
    font-weight: 400;
  }
}
