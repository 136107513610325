@import "../../Theme/_colors.scss";

.card.resource-card {
  background-color: $ihealWhite;
  color: $ihealBlack;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  // filter: drop-shadow(1mm 1mm 2mm rgb(230, 230, 230));
  border-radius: 25px;
  border: 1px solid #f3f3f4;

  .card-title {
    font-size: 1.3em;
    font-weight: 600;
    text-align: center;
    line-height: 1.2;

    text-align: left;
    margin-bottom: 10px;
    flex: 1;

    a {
      text-decoration: none;
      color: #1f1f21;
      font-weight: 400;
    }
  }
  .resource-saved {
    padding-left: 10px;

    .svg-inline--fa {
      font-size: 1.5em;
    }
  }

  .card-phone {
    margin-top: 10px;

    a {
      text-decoration: none;
      color: #1f1f21;
      font-weight: 400;
    }
  }

  .card-description {
    font-size: 1em;
    font-weight: 300;
    margin-top: 10px;
    text-align: left;
    // margin-left: 30%;
    margin-right: 0;
    margin-left: 0;
  }

  .phone-icon {
    // position: absolute;
    // left: 31%;
    margin-right: 10px;
  }

  .resource-logo {
    width: 23%;
    max-width: 94px;
    margin-right: 20px;
  }
  .resource-icon {
    // position: absolute;
    // top: 18%;
    // left: 3%;
    // width: 23%;
    // max-width: 94px;
    max-width: 100%;
    border-radius: 50%;
  }
}
