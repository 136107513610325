@import "../../Theme/colors";

.setting-screen {
  .pillar-title {
    // font-size: 1.9em;
    // font-weight: 700;
  }

  .pillar-subtitle {
    font-size: 1.7em;
    font-weight: 700;
  }

  .center-text {
    margin-left: auto;
    margin-right: auto;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 200px;
    width: 100%;
  }

  .signup-image {
    width: 100%;
    height: 100%;
  }

  .icon {
    width: 15px;
  }

  .input-field .svg-inline--fa {
    margin-right: 10px;
  }

  .rounded-button {
    color: $ihealWhite;
  }
}
