.default-quiz {
  .scroll-overflow {
    overflow-y: auto;
  }

  .carousel2-item {
    font-size: 1em;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;

    h1 {
      word-break: break-word;
    }
  }

  .quiz-questions-label {
    // margin-left: 10%;
    // margin-right: 10%;
    font-size: 1em;
    width: 100%;
  }

  .quiz-questions-progress-holder {
    margin-top: 2%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 15px;
    border: 2px solid #222222;
    border-radius: 25px;
  }

  .quiz-questions-progress {
    background-color: black;
    height: 100%;
    border-radius: 25px;
  }

  .activity-title {
    font-size: 1.9em;
    font-weight: 700;
  }

  .activity-description {
    font-size: 1.2em;
    font-weight: 300;
  }

  .center-text {
    margin-left: auto;
    margin-right: auto;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 50%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  .activity-image {
    // width: 100%;
    // height: 100%;
  }

  .listitem li {
    margin-bottom: 1em;
  }

  .testRotate {
    width: 300px;
    height: 50px;
    border: 1px solid black;
    padding: 20px;
    margin: 0px auto;
    margin-top: 50px;
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;
    transition: transform 1s;
  }

  ons-carousel[fullscreen] {
    height: auto;
  }

  .fade-bottom {
    -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
    mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  }

  .wheel-container {
    width: 100%;
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;
    transition: transform 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 1;
    min-height: 150px;
    height: 25%;
    max-height: 250px;
    overflow: hidden;
    position: relative;
  }

  .wheel-image {
    // width: 80%;
    /* height: 140px; */
    object-position: center top;
    object-fit: cover;
    width: 90%;
    max-width: 450px;
    width: 100%;
    height: 100%;
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;
    transition: transform 1s;
    transform: rotate(45deg);
  }
}
