@import "../../Theme/colors";

#word-quilt {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  margin: 0 auto;

  .list-button {
    margin: 0;
    border-radius: 0;
    flex: 1 1 25%;
    outline: 1px dashed $ihealWhite;
    outline-offset: -2px;
  }

  .rounded-button {
    font-size: 16px;
    font-weight: normal;
    padding: 14px 10px;
  }
}
