.login {
  h1 {
    text-align: center;
  }

  .page-header {
    height: auto;
    background-position: bottom;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    max-width: 250px;
    padding-top: 20px;
  }
}

.signup {
  .page__background {
    background-color: #26a18f;
    background-image: url(../../Assets/images/bg-cloud.jpg);
    background-blend-mode: multiply;
    background-position: bottom center;
  }

  .scroll-overflow {
    overflow-y: auto;
  }

  .page-header {
    h2 {
      font-weight: 600;
      margin: 0;
      font-size: 22px;
      text-transform: lowercase;
      letter-spacing: 3px;
    }
  }

  h1 {
    font-weight: 300 !important;

    b {
      font-weight: 600 !important;
    }
  }

  .svg-inline--fa {
    margin-right: 5px;
  }
  label {
    margin: 0 10px;
  }

  .icon {
    width: 15px;
  }

  &.info {
    .checkbox {
      margin-top: 3px;
    }
    label {
      margin-right: 0;
    }
  }
}

.tandc a {
  color: #333;
}

// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
//     .login .page-header {
//         background-position: top;
//     }
// }
