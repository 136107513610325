@import "../../Theme/colors";

.reading-activity {
  .activitySave {
    text-align: right;

    svg {
      font-size: 1.2em;
      padding: 0 12px;
      margin-right: 4px;
    }
  }

  .block-style {
    display: block;
  }

  .pillar-title {
    font-size: 1.9em;
    font-weight: 700;
  }

  .pillar-subtitle {
    font-size: 1.2em;
  }

  .saved-icon {
    position: absolute;
    left: 84%;
    top: 4%;
  }

  .center-text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 200px;
    width: 100%;
    position: relative;
  }

  .big-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    width: 100%;
    margin-top: 20px;
  }

  .signup-image {
    width: 100%;
    height: 100%;
  }

  .icon {
    width: 15px;
  }

  // resources on article page
  #home-search {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: 50px;
    font-size: 1.4em;
  }
  #home-search .search-input {
    width: 100%;
    display: inline-block;
    height: 100%;
    border-radius: 20px;
    border: 1px solid #222222;
  }

  .rounded-button-outline {
    white-space: normal;
    line-height: 1.5;
  }

  .rounded-button-outline.accordion {
    padding: 0;
    background: $ihealWhite;
    color: $ihealBlack;
    margin-bottom: 10px;
    border: none;

    .heading div {
      padding: 10px 20px;
    }
  }
  .ReactCollapse--collapse {
    background: $ihealWhite;
    color: $ihealBlack;
  }
  .ReactCollapse--content {
    font-size: 0.8em;
  }

  // embedded videos
  figure {
    margin: 0 auto;

    iframe {
      max-width: 100%;
    }
  }
}
