@import "../../Theme/_colors.scss";

#bottom-bar {
  color: $ihealWhite;

  .bottombar-item {
    width: calc(25% - 1px);
    background: rgba(35, 35, 35, 0.7);
    text-transform: lowercase;

    div {
      font-size: 0.8em;
    }
  }

  .tabbar-icon {
    // width: 13%;
    max-height: 20px;
    padding-top: 10px;
  }

  .tabbar-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
