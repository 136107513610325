.resources-screen {
  .pillar-icon {
    font-size: 1.9em;
    align-self: center;
  }

  .pillar-title {
    font-size: 1.9em;
    font-weight: 700;
  }

  .pillar-subtitle {
    font-size: 1.7em;
    font-weight: 700;
  }

  .center-text {
    margin-left: auto;
    margin-right: auto;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
  }
}
