.intro-screen {
  .page__background {
    background-image: url(../../Assets/images/gradient-purple-blue.png);
  }

  .scroll-overflow {
    overflow-y: auto;
  }

  .carousel-item {
    img {
      max-height: 300px;
      max-width: 300px;
    }
  }

  .carousel-image {
    height: 70%;
  }

  .fab {
    background-color: #222;
    color: #fff;
  }

  .circle-outline {
    font-weight: 400 !important;
  }

  .ons-swiper {
    overflow-y: auto;
  }
}

.body-text {
  margin-inline: 3rem;
  font-size: 1rem;
}

@media (min-width: 480px) {
  .body-text {
    margin-inline: 6rem;
    font-size: 1.2rem;
  }
}

@media (min-width: 764px) {
  .body-text {
    margin-inline: 10rem;
    font-size: 1.3rem;
  }
}
