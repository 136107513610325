@import "../../Theme/colors";

#activity-quiz-card {
  width: 100%;

  .card {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    // filter: drop-shadow(1mm 1mm 2mm rgb(230, 230, 230));
    border-radius: 30px;
    // min-height: 140px;
    background-color: transparent;
    border: 1px solid $ihealWhite;
    box-shadow: none;
  }

  .card-title {
    // margin-right: 30px;
    margin: 30px 15px;
    text-align: center;
    font-size: 1.5em;
  }

  .card-time {
    text-align: end;
    font-size: 0.8em;
  }

  .active-button {
    background-color: $ihealWhite;
  }
}
