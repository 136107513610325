.location-screen {
  .page__background {
    background-color: #26a18f;
  }

  .location-container {
    overflow-y: auto;
  }

  .scroll-overflow {
    overflow-y: auto;
  }

  .location-name {
    border-radius: 25px;
    font-size: 1.2em;
    font-weight: 700;
    padding: 15px;
    text-align: center;
  }
  h1 {
    font-weight: 300 !important;

    b {
      font-weight: 600 !important;
    }
  }
}
