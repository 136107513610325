.default-activity {
  .activitySave {
    text-align: right;

    svg {
      font-size: 1.2em;
      padding: 0 12px;
      margin-right: 4px;
    }
  }

  .pillar-title {
    font-size: 1.9em;
    font-weight: 700;
  }

  .pillar-subtitle {
    font-size: 1.7em;
    font-weight: 700;
  }

  .saved-icon {
    position: absolute;
    left: 84%;
    top: 4%;
  }

  .center-text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 200px;
    width: 100%;
    position: relative;
  }

  .signup-image {
    width: 100%;
    height: 100%;
  }

  .icon {
    width: 15px;
  }
}
