// Thin 100
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

// Thin 100 Italic
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

// ExtraLight 200
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

// ExtraLight 200 Italic
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

// Light 300
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

// Light 300 Italic
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

// Regular 400
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// Regular 400 Italic
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

// Medium 500
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

// Medium 500 Italic
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

// SemiBold 600
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

// SemiBold 600 Italic
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

// Bold 700
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

// Bold 700 Italic
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

// ExtraBold 800
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

// ExtraBold 800 Italic
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

// Black 900
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

// Black 900 Italic
@font-face {
  font-family: "Poppins";
  src:
    local("Poppins"),
    url("./fonts/Poppins/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
