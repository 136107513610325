@import "../../Theme/_colors.scss";

.pillar-screen {
  .pillar-icon {
    font-size: 1.9em;
    align-self: center;
  }
  .pillar-title {
    font-size: 1.9em;
    font-weight: 700;
  }

  .pillar-subtitle {
    font-size: 1.7em;
    font-weight: 700;
  }

  .center-text {
    margin-left: auto;
    margin-right: auto;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 200px;
    width: 100%;
  }

  .signup-image {
    // width: 100%;
    // height: 100%;
  }

  .icon {
    width: 15px;
  }

  .header-container {
    padding-top: 10px;
    height: 10%;
    align-items: center;
    background-color: #ffffff;
  }

  .activity-icon {
    box-shadow:
      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }
}
