@import "../../Theme/colors";

.saved-screen {
  .pillar-icon {
    font-size: 1.9em;
    align-self: center;
  }

  .pillar-title {
    font-size: 1.9em;
    font-weight: 700;
  }

  .pillar-subtitle {
    font-size: 1.7em;
    font-weight: 700;
  }

  .center-text {
    margin-left: auto;
    margin-right: auto;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 100%;
  }

  .signup-image {
    width: 100%;
    height: 100%;
  }

  .icon {
    width: 15px;
  }

  #saved-nav {
    width: 100%;
    margin-bottom: 20px;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      justify-content: center;
    }

    li {
      text-align: center;
      white-space: nowrap;
      font-size: 0.8em;
      width: 100%;
      padding: 8px 0;
      color: $ihealBlack;
      box-shadow:
        rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

      // from .rounded-button
      background-color: #f2f2f2;
      color: #0e8b82;
      cursor: pointer;
      text-align: center;
      text-transform: none;

      // from .rounded-ends
      border-radius: 75px;
    }

    .active {
      background: $activeSaved;
      color: $ihealWhite;
    }
  }

  .saved-quote {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 8px;

    .svg-inline--fa {
      font-size: 1.5em;
    }

    p {
      margin-top: 0;
    }
  }

  // from Home.scss
  .resource-card {
    border-radius: 30px;
    box-shadow: none;
    margin-left: 1px;
  }
  .card-description {
    margin-top: 20px;
    font-size: 1.4em;
  }
  .result-card {
    $headerHeight: 42px;
    position: relative;
    border-radius: 16px;
    box-shadow: 0 10px 6px 0 rgba(0, 0, 0, 0.1);
    background: white;
    padding: 1rem;
    padding-bottom: calc(1rem + 6px);
    margin-left: 1px;
    color: black;
    overflow: hidden;
    display: grid;
    grid-template-rows: $headerHeight 0fr;
    transition: all 0.5s ease-in-out;
    &.expanded {
      grid-template-rows: $headerHeight 1fr;
    }
    img {
      display: none;
    }
    header {
      height: $headerHeight;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      h2 {
        margin: 0;
        padding: 0;
        font-size: 1.2em;
      }
      .card-right {
        font-size: 0.9em;
        line-height: 0.9em;
        margin: 0;
        padding: 0;
        font-style: italic;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 12px;

        svg {
          height: 18px;
        }
      }
    }
    .content-container {
      overflow: hidden;
    }
    .result-content {
      // margin-top: -100%;
      // display: grid;
      // grid-template-rows: 0fr;
      // max-height: 0px;
      // height: 0px;
      &.expanded {
        // margin-top: 0;
        // grid-template-rows: 1fr;
        //   max-height: none;
      }
    }
    .expand-icon {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 12px;
      // background-color: #8eabf2;
      background: linear-gradient(
        90deg,
        #3ac6f4 0%,
        #7dcfda 28.46%,
        #78cad6 40.12%,
        #6abcc9 53.94%,
        #52a4b3 68.86%,
        #318496 84.44%,
        #085c71 100%
      );
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 0.8rem;
        color: black;
      }
    }
  }
}
