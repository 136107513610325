@import "../../Theme/colors";

.activity-icon {
  padding: 0 !important;
  color: $ihealBlack !important;

  .heading {
    line-height: 1.2;
    white-space: normal;
    text-align: left;
    font-weight: 600;

    div {
      padding: 10px 20px;
    }
  }

  .activity-type {
    line-height: normal;
    font-size: 0.8em;
    align-self: flex-end;
    align-items: center;
    font-style: italic;
  }

  .activity-saved {
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
  }
}
