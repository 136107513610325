@import "../../Theme/colors";

textarea {
  &.text-input--material {
    width: 100%;
    height: 200px !important;
    border: 1px solid $ihealWhite;

    &:focus {
      border-color: $ihealGreen;
    }
  }
}

.rounded-button-outline {
  white-space: normal !important;
  line-height: 1.5 !important;
}
