#activity-icon {
  width: 30%;
  margin: 2% 0;
  filter: none !important;
  border-radius: none;
  box-shadow: none;
  /* display: inline; */
  // float: left;
  text-align: center;

  .activity-title {
    /* margin-left: 29%; */
    text-align: center;
    font-size: 1em;
    font-weight: 700;
    margin-top: 10%;
    text-transform: uppercase;

    @media only screen and (min-width: 600px) {
      font-size: 1.3em;
    }
  }

  .circle-holder {
    position: relative;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }

  .activity-icon {
    // position: absolute;
    // left: 25%;
    // top: 21%;
    width: 50%;
  }

  .circle {
    width: 100%;
    height: 100%;
  }

  .rectangle {
    position: absolute;
    // top: 68%;
    bottom: -10px;
    /* left: 10%; */
    left: 0;
    width: 100%;
    height: 30%;
    border: solid 1px;
    z-index: 10;
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .activity-note {
    // position: absolute;
    font-size: 1.1em;
    font-weight: 300;
    // margin-bottom: 10px;
    // margin-top: -18%;
    z-index: 20;
    // margin-left: 50%;
    // transform: translate(-50%, -50%);
  }
}
