.default-quiz {
  .carousel2-item {
    font-size: 1em;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
  }

  .quiz-questions-label {
    // margin-left: 10%;
    // margin-right: 10%;
    font-size: 1em;
  }

  .quiz-questions-progress-holder {
    margin-top: 2%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    height: 15px;
    border: 2px solid #222222;
    border-radius: 25px;
  }

  .quiz-questions-progress {
    background-color: black;
    height: 100%;
    border-radius: 25px;
  }

  .score-range {
    margin: 0.6em auto;
    font-size: 1.25em;
  }

  .activity-title {
    font-size: 1.9em;
    font-weight: 700;
  }

  .activity-description {
    font-size: 1.2em;
    font-weight: 300;

    img {
      max-height: 300px;
      max-width: 300px;
    }
  }

  .center-text {
    margin-left: auto;
    margin-right: auto;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 50%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }

  .activity-image {
    // width: 100%;
    // height: 100%;
  }
}
