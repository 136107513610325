$yellow: #ffc86c;
$green: #b6da83;
$blue: #a9d5ff;
$purple: #d3bee3;
$red: #ffc4bc;
$brown: #c2b49b;

$ihealWhite: #f2f2f2;
$ihealGreen: #0e8b82;
$ihealBlack: #232323;

$bgGradientHome: linear-gradient(
  0deg,
  rgba(9, 127, 155, 1) 0%,
  rgba(24, 155, 158, 1) 19.48%,
  rgba(36, 176, 160, 1) 38.52%,
  rgba(40, 184, 161, 1) 51.04%,
  rgba(38, 174, 152, 1) 59.72%,
  rgba(33, 146, 129, 1) 74.61%,
  rgba(25, 102, 91, 1) 93.78%,
  rgba(22, 86, 77, 1) 100%
);
$bgGradientSaved: linear-gradient(
  0deg,
  rgba(58, 198, 244, 1) 0%,
  rgba(125, 207, 218, 1) 28.46%,
  rgba(120, 202, 214, 1) 40.12%,
  rgba(106, 188, 201, 1) 53.94%,
  rgba(82, 164, 179, 1) 68.86%,
  rgba(49, 132, 150, 1) 84.44%,
  rgba(8, 92, 113, 1) 100%
);
$activeSaved: linear-gradient(
  90deg,
  rgba(58, 198, 244, 1) 0%,
  rgba(125, 207, 218, 1) 28.46%,
  rgba(120, 202, 214, 1) 40.12%,
  rgba(106, 188, 201, 1) 53.94%,
  rgba(82, 164, 179, 1) 68.86%,
  rgba(49, 132, 150, 1) 84.44%,
  rgba(8, 92, 113, 1) 100%
);
$bgGradientResources: linear-gradient(
  180deg,
  rgba(69, 52, 122, 1) 0%,
  rgba(139, 83, 152, 1) 64.1%,
  rgba(174, 98, 167, 1) 100%,
  rgba(192, 111, 172, 1) 100%,
  rgba(220, 131, 179, 1) 100%,
  rgba(238, 144, 183, 1) 100%,
  rgba(244, 148, 185, 1) 100%
);

$bgGradientSafety: linear-gradient(
  0deg,
  rgba(9, 127, 155, 1) 0%,
  rgba(24, 155, 158, 1) 0%,
  rgba(36, 176, 160, 1) 0%,
  rgba(40, 184, 161, 1) 0%,
  rgba(40, 181, 159, 1) 45.21%,
  rgba(41, 148, 132, 1) 73.89%,
  rgba(42, 122, 110, 1) 100%
);
$bgGradientBasicNeeds: linear-gradient(
  0deg,
  rgba(22, 102, 174, 1) 0%,
  rgba(21, 126, 193, 1) 12.74%,
  rgba(21, 146, 208, 1) 27.25%,
  rgba(20, 158, 218, 1) 41.8%,
  rgba(20, 162, 221, 1) 56.38%,
  rgba(107, 202, 207, 1) 99.82%,
  rgba(40, 181, 159, 1) 100%
);
$bgGradientHealth: linear-gradient(
  0deg,
  rgba(254, 238, 122, 1) 0%,
  rgba(217, 225, 113, 1) 11.87%,
  rgba(160, 206, 99, 1) 28.19%,
  rgba(137, 188, 94, 1) 47.05%,
  rgba(80, 142, 80, 1) 86.29%,
  rgba(58, 125, 75, 1) 100%
);
$bgGradientConnecting: linear-gradient(
  0deg,
  rgba(69, 52, 122, 1) 0%,
  rgba(139, 83, 152, 1) 64.1%,
  rgba(174, 98, 167, 1) 100%,
  rgba(192, 111, 172, 1) 100%,
  rgba(220, 131, 179, 1) 100%,
  rgba(238, 144, 183, 1) 100%,
  rgba(244, 148, 185, 1) 100%
);
$bgGradientClaiming: linear-gradient(
  0deg,
  rgb(245, 134, 114) 0%,
  rgb(240, 85, 115) 35.2%,
  rgb(199, 72, 105) 67.26%,
  rgb(118, 47, 85) 100%
);
$bgGradientFamily: linear-gradient(
  0deg,
  rgba(254, 209, 61, 1) 0%,
  rgba(249, 157, 61, 1) 50.84%,
  rgba(239, 63, 99, 1) 99.46%
);
